<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="可用" :value="0"></el-option>
          <el-option label="停用" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="openAdd">新增场地</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
           <el-tag v-if="row.status === 0">可使用</el-tag>
            <el-tag type="danger" v-if="row.status === 1">停止使用</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
           <el-button type="primary" size="small" @click="openEdit(row)"
                  >修改</el-button
                >
          <el-button type="info" v-if="row.status == 0" size="small" @click="updateStatus(row,1)"
                  >停用</el-button
                >
          <el-button type="warning"  v-if="row.status == 1"  size="small" @click="updateStatus(row,0)"
                  >使用</el-button
                >
                 <el-button type="success" size="small" @click="openService(row)"
                  >场地服务</el-button
                >
                  <el-button type="primary" size="small" @click="toScheduling(row)"
                  >场地排期</el-button
                >
        </div>
      </template>
    </auto-table>

     <addDevice ref="device" @success="getList(1)"></addDevice>
     <serviceList ref="service"></serviceList>
  </div>
</template>

<script>
import serviceList from '../service/serviceList.vue';
import addDevice from '../addDevice/addDevice.vue';
export default {
    components:{
        addDevice,
        serviceList
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "容量", value: "max_people" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 0,
    };
  },

  mounted() {
     this.id = this.$route.query.id;
    this.getList(1);
  },
  methods: {
    init(){
      if(this.DataList.length == 0){
        this.getList(1);
      }
    },
    toScheduling(row){
       this.$router.push({name:'serviceScheduling',query:{vid:row.venue_id}})

    },
    openService(row){
      this.$refs.service.init(row)
    },
    openAdd() {
       let comm =  { venue_id: this.id}
        this.$refs.device.open(comm)
    },
     openEdit(row) {
         this.$refs.device.open(row)
    },
    
    // 更新设备
    updateStatus(row, status) {
      let url = "/shared/venueDevice/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: status,
        })
        .then((res) => {
           this.getList(1);
        });
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/shared/venueDevice/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          venue_id:this.id,
          status:this.status
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>