<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="上架中" :value="1"></el-option>
          <el-option label="下架" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="openAdd">新增团购商品</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
           <el-tag v-if="row.status === 1">上架中</el-tag>
            <el-tag type="danger" v-if="row.status === 2">下架</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
           <el-button type="primary" size="small" @click="openEdit(row)">修改</el-button>
        </div>
      </template>
    </auto-table>

     <AddorEdit ref="add" @success="getList(1)"></AddorEdit>
  </div>
</template>

<script>
import AddorEdit from './AddorEdit.vue';

export default {
    components:{
        AddorEdit
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "容量", value: "max_people" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 1,
    };
  },

  mounted() {
     this.id = this.$route.query.id;
   
  },

  methods: {
    init(){
      if(this.DataList.length == 0){
        this.getList(1);
      }
 
    },
    openAdd() {
       let comm =  { venue_id: this.id}
          this.$refs.add.open(comm)
    },
     openEdit(row) {
         this.$refs.add.open(row)
    },
    
    // 更新设备
    handlerDevice(row, status) {
      let url = "user/venue/device/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: status,
        })
        .then((res) => {
          this.show = false;
          this.getDeviceList();
        });
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/shared/groupProduct/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          venue_id:this.id
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>