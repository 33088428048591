<template>
  <div class="container">
    <div  style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-descriptions title="场馆详情">
        <el-descriptions-item label="场馆名称">
          {{ orgInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="场馆地址">
          {{ orgInfo.address }}
        </el-descriptions-item>
        <el-descriptions-item label="经营类目">
          {{ orgInfo.categories_child }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <span v-if="orgInfo.status == 0" style="color: #32cd32">正常</span>
          <span v-if="orgInfo.status == 1" style="color: #ff6262">已冻结</span>
        </el-descriptions-item>
        <el-descriptions-item label="类型">
          场馆
        </el-descriptions-item>

        <el-descriptions-item label="场馆介绍">
          {{ orgInfo.introduction_content }}
        </el-descriptions-item>

        <el-descriptions-item label="封面">
          <el-image
            style="width: 150px; height: 150px"
            :src="orgInfo.images"
            fit="cover"
            :preview-src-list="[orgInfo.images]"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="场馆图片">
          <el-image
            v-for="(pic, index) in orgInfo.introduction_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="orgInfo.introduction_pic.split(',')"
            :key="index"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    
 <el-tabs v-model="activeName" @tab-click="tabClick">
     <el-tab-pane label="场地" name="device">
        <deviceList ref="device"></deviceList>
     </el-tab-pane>
      <el-tab-pane label="团购商品" name="product">
        <productList ref="product"></productList>
     </el-tab-pane>
       <el-tab-pane label="预约排期" name="appointment">
        <appointment ref="appointment"></appointment>
     </el-tab-pane>
     
    </el-tabs>

  </div>
</template>
  
<script>

import productList from '../components/product/productList.vue';
import appointment from '../components/venue/appointment.vue';
import deviceList from '../components/venue/deviceList.vue';
export default {
 components:{
    deviceList,
    productList,
    appointment
 },
  data() {
    return {
      id: "",
      nav: { firstNav: "汇合空间", secondNav: "场馆详情" },
      orgInfo: {},
      activeName: "device",
    };
  },
  methods: {
    tabClick(v){
      this.$refs[v.name].init()
    },
     getOrgDetails() {
      let url = "/shared/sharedVenue/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          this.orgInfo = res.data.data
        });
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getOrgDetails();
  },
};
</script>
  
<style></style>
  