<template>
  <div class="container">
     <el-date-picker
      v-model="changeTime"
      type="date"
      value-format="yyyy-MM-dd"
      @change="selectTime"
      placeholder="选择日期">
    </el-date-picker>

    <div class="p-t">排期表</div>

     <appointmentTable
      ref="appt"
      setPeople
      type='show'
      :datas="appointInfo"
    ></appointmentTable>
    <div v-if="appointInfo.length == 0">
      <el-empty :image-size="70"></el-empty>
    </div>

  <div class="p-t">预约情况</div>
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="待使用" :value="2"></el-option>
          <el-option label="已核销" :value="3"></el-option>
          <el-option label="下架" value=""></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addActivity">新增活动</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #time="{ row }">
        <div>
         {{row.start_time.slice(0,-3)}} - {{row.end_time.slice(-8,-3)}}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">已取消</el-tag>
          <el-tag type="primary" size="small" v-if="row.status === 2"
            >待使用</el-tag
          >
           <el-tag type="success" size="small" v-if="row.status === 3"
            >已核销</el-tag
          >
           <el-tag type="info" size="small" v-if="row.status === 10"
            >已过期</el-tag
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
import appointmentTable from '../appointment/appointmentTable.vue';
export default {
    components:{
appointmentTable
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "场地", value: "title" },
        { name: "预约人", value: "nick_name" },
        { name: "状态", value: "status", type: "custom" },
        { name: "预约时间", value: "time", type: "custom" },
      ],
      Total: 0,
      status: '',
      id:'',
      appointInfo:[],
      changeTime: new Date().Format('yyyy-MM-dd'),
    };
  },

  mounted() {
     this.id = this.$route.query.id;
  },

  methods: {
    init(){
        this.getDayAppointment()
        this.getList(1)
    },
    selectTime(v){
       this.getDayAppointment()
       this.getList(1)
    },
    getDayAppointment() {
        if(!this.id){
            return
        }
        this.$get(
            "/shared/deviceScheduling/queryDayAppointInfoAll", {
                venue_id: this.id || '',
                start_time: this.changeTime + " 00:00:00",
                end_time: this.changeTime + " 23:59:59",
            },
        ).then((res) => {
            this.appointInfo = res.data.data;

        });
    },
    addActivity() {
      this.$router.push({ name: "addActivity" });
    },
    updateInfo(row) {
      this.$router.push({ name: "addActivity", query: { aid: row.id } });
    },
    del(row){
      this.$confirm("确定删除").then(()=>{
         this.$post('/user/businessActivityType/update',{id:row.id,status:3}).then(res=>{
          this.getList(1);
        })
      })
    },
    updateStatus(row,status){
        if(status == 2){
          if(new Date(row.end_time).getTime() < Date.now()){
            this.$message("活动已结束，不能上架")
            return
          }
        }

        this.$post('/user/businessActivityType/update',{id:row.id,status}).then(res=>{
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/userAppointment/list/page",
        params: {
          currentPage: page,
          pageSize: 10,
          attend_class_start_time: this.changeTime + " 00:00:00",
			attend_class_end_time: this.changeTime + " 23:59:59",
			  venue_id: this.id ,
			orderBy:'start_time',
            master_type: "shared_device",
            status:this.status || null
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.p-t{
    font-size: 16px;
    margin: 30px 20px;
    font-weight: 600;
}
</style>